export const Testdata = [
  {
    description:
      "The Raados series of jeans offers exceptional all-day comfort and a superb fit. These slim-fit jeans taper slightly below the thigh area, making them suitable for most body types (unlike skinny-fit jeans). A quality product from Raados, highly recommended!",

    view: "Happy Customer",
  },

  {
    description:
      "It's original and fitting is also good except length which is loooong as per size.They provide much soft fabric of same fabric ratio which is very gentle on skin and verrry comfortable in summers",

    view: "Happy Customer",
  },
];
