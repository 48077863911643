export const PLACE_NEW_ORDER = "getPlaceNewOrderfun";
export const CANCEL_NEW_ORDER = "getCancelNewOrderfun";
export const GET_NEW_ORDER = "getNewOrderfun";
// export const UPDATE_USER_ADDRESS = "getUpdateUserAddressfun";
// export const ADD_USER_ADDRESS = "getAddUserAddressfun";
// export const DELETE_USER_ADDRESS = "getDeleteUserAddressfun";

export const ERROR_PLACE_NEW_ORDER = "errorgetPlaceNewOrderfun";
export const ERROR_CANCEL_NEW_ORDER = "errorgetCancelNewOrderfun";
export const ERROR_GET_NEW_ORDER = "errorgetNewOrderfun";
// export const ERROR_UPDATE_USER_ADDRESS = "errorgetUpdateUserAddressfun";
// export const ERROR_ADD_USER_ADDRESS = "errorgetAddUserAddressfun";
// export const ERROR_DELETE_USER_ADDRESS = "errorgetDeleteUserAddressfun";
